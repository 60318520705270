<template>
  <sf-card
    :titleVisible="false"
  >
    <el-row class="overview">
      <el-col
        v-for="item in displayedData"
        :key="item.key"
        :span="12"
        class="overview-item"
      >
        <sf-card :titleVisible="false">
          <p class="value">{{item.value}}</p>
          <p class="desc">{{item.desc}}</p>
          <div v-if="item.showRate" :class="`rate ${item.increased && 'increased'}`">
            {{item.rate * 100}}%
            <span class="rate-desc">
              环比
              <span class="rate-status">{{item.increased ? '增加' : '下降'}}</span>
            </span>
          </div>
        </sf-card>
      </el-col>
    </el-row>
  </sf-card>
</template>

<script>
import { get } from 'lodash';

export default {
  props: {
    details: Object,
  },
  computed: {
    displayedData() {
      const list = [
        {
          key: 'totalInstalledApps',
          value: 0,
          desc: '当前软件安装总数(个)',
          showRate: false,
          fixed: 0
        },
        {
          key: 'lastWeekInstalledApps',
          value: 0,
          desc: '过去7天软件安装数量(个)',
          showRate: false,
          fixed: 0
        },
        {
          key: 'lastWeekUsageDuration',
          value: 0,
          desc: '过去7天总使用时长(小时)',
          increased: true,
          rate: 0,
          rateKey: 'lastWeekUsageDurationCompare',
          showRate: true,
          fixed: 2
        },
        {
          key: 'lastWeekNonbizUsageHours',
          value: 0,
          desc: '过去7天非业务软件使用(小时/天)',
          increased: true,
          rate: 0,
          rateKey: 'lastWeekNonbizUsageHoursCompare',
          showRate: true,
          fixed: 2
        },
      ];
      return list.map((item) => ({
        ...item,
        value: get(this, ['details', item.key], 0).toFixed(item.fixed),
        rate: get(this, ['details', item.rateKey], 0),
        increased: get(this, ['details', item.rateKey], 0) > 0,
      }));
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  overflow: hidden;
}
.overview {
  margin: -21px;
}
.overview-item {
  border: 1px solid $color-border-secondary;
  border-top: 0;
  border-left: 0;
}
.value {
  margin-top: $padding-xl;
  margin-bottom: $padding-s;
  font-size: 40px;
  font-weight: 600;
  color: $color-black;
  text-align: center;
}
.desc {
  margin-bottom: $padding;
  @include text-primary($color-text-regular);
  text-align: center;
}
.rate {
  @include flex-center();
  @include text-primary($color-text-regular);
  &:before {
    content: '';
    margin-right: $padding-s;
    margin-top: 10px;
    @include triangle-down(10px, $color-danger);
  }
  .rate-desc {
    margin-left: $padding;
    @include text-primary($color-text-regular);
  }
  .rate-status {
    color: $color-danger;
  }
  &.increased {
    .rate-status {
      color: $color-success;
    }
    &:before {
      content: '';
      margin-top: -10px;
      @include triangle-up(10px, $color-success);
    }
  }
}
</style>
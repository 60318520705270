<template>
    <div class="chart" ref="chart"></div>

</template>

<script>
import * as echarts from 'echarts';
import colors from '@constants/colors'; 
import { reverse, get, cloneDeep } from 'lodash';

let chart = null;

const typeColors = colors.softwareColors;

export default {
  props: {
    dataDistribution: Array,
  },
  watch:{
			dataDistribution:{
				// 此处监听variable变量，当期有变化时执行
				handler(){
					this.renderChart()
				}
			}
	},
  data() {
    return {
    }
  },
  mounted() {
    this.renderChart();
    window.addEventListener('resize', chart.resize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', chart.resize);
  },
  methods: {
    renderChart() {
      const dataDistribution = reverse(cloneDeep(this.dataDistribution));

      chart = echarts.init(this.$refs.chart);
      const seriesKeys = get(this.dataDistribution, [0, 'data'], []).map(({ name }) => name);
      const lengends = seriesKeys;

      const series = seriesKeys.map((key)=>{
        let value = dataDistribution.map(({ data }) => data.find(({ name }) => name === key));

        return {
          name: key,
          type: 'bar',
          stack: 'total',
          label: {
            show: true,
            formatter: ({ value: labelvalue }) => (labelvalue >= 0.1 ? `${(labelvalue * 100).toFixed(1)}%` : ''),
            color: '#333',
            fontSize: 14,
            // fontWeight: 500,
          },
          emphasis: {
            focus: 'none',
          },
          data: value.map(({ rate }) => rate),
          barWidth: '60%',
        };
      });

      const axisLine = {
        show: true,
        lineStyle: { color: '#DFDFDF' },
      }

      const option = {
        tooltip: {
          show: true,
          formatter: (data) => `${data.seriesName}<br/>${data.name} ${(data.value * 100).toFixed(1)}%`
        },
        color: typeColors,
        legend: {
          show: true,
          data: lengends,
          bottom: 20,
          itemGap: 30,
          itemWidth: 14,
          itemHeight: 14,
          icon: 'circle',
          textStyle: {
            color: '#373E4E',
            fontSize: 12,
          }
        },
        grid: {
          left: '0',
          right: '0',
          bottom: '20%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: dataDistribution.map(({week}) => week),
          axisTick: { show: false },
          axisLine,
          axisLabel: {
            fontSize: 18,
            color: '#373E4E',
            fontWeight: 500
          }
        },
        yAxis: {
          realtimeSort: true,
          sortSeriesIndex: 0,
          type: 'value',
          min: 0,
          max: 1,
          interval: 0.5,
          axisLabel: {
            formatter: function (value) {
                            return value*100+"%";
                        },
            color: '#A9A9A9',
            fontSize: 14,
          },
          axisLine,
          splitLine: { show: true },
        },
        series,
      }
      chart.setOption(option);
    }
  },
}
</script>

<style lang="scss" scoped>
.chart {
  height: 100%;
  min-height: 520px;
}
</style>
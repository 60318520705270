export const LOGIN = '/AccAdmin/doLogin';
export const LOGOUT = '/AccAdmin/doExit';

export const HOME_EFFECTIVE_WORK_HOURS = '/employee/valid_work_rate';
export const HOME_ALARM = '/employee/early_warning';
export const HOME_ONLINE_USER = '/employee/online';
export const HOME_ONLINE_USER_DETAIL = '/employee/online_detail';
export const HOME_TOP_USER = '/employee/top_user';
export const HOME_SOFTWARE_USAGE = '/employee/software_usage';
export const HOME_SOFTWARE_USAGE_TOP = '/employee/software_usage_top_n';
export const HOME_SERVER_USAGE = '/employee/server_usage';

export const ALARM_DETAIL = '/employee/early_warning_detail';

export const SOFTWARE_USAGE = '/employee/software_usage_detail';

export const ORGANIZATION = '/sp_organization';
export const EMPLOYEE = '/sp_employee';
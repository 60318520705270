<template>
  <el-row :gutter="20" class="software">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <Types
        class="software-card"
        :types="types"
        :top5="relatedAppTop5"
      />
    </el-col>
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <Overview class="software-card" :details="overview" />
      <NonOfficeApp
        class="software-card"
        :list="nonOfficeApps"
      />
    </el-col>
  </el-row>
</template>

<script>
import { get } from 'lodash';
import * as api from '@constants/apis';
import Types from './components/types';
import Overview from './components/overview';
import NonOfficeApp from './components/nonOfficeApp';

let loading = null;

export default {
  data() {
    return {
      types: [],
      relatedAppTop5: [],
      nonOfficeApps: [],
      overview: {},
    }
  },
  components: {
    Types,
    Overview,
    NonOfficeApp,
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        customClass: 'sf-loading',
        target: document.querySelector('.software')
      });
      try {
        const res = await this.$httpRequest.get(api.SOFTWARE_USAGE);

        this.types = get(res, ['data', 'lastNWeeksAppDist'], []);
        this.relatedAppTop5 = get(res, ['data', 'bizAssociatedAppsTopN'], []);
        this.nonOfficeApps = get(res, ['data', 'nonbizAppsUsageDist'], []);
        this.overview = get(res, ['data', 'appInstallUsageDist'], {});
      } catch (err) {
        console.log(err);
      } finally {
        loading.close();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.software {
  margin-top: -$padding;
  &-card {
    margin-top: $padding;
  }
}
</style>
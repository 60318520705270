<template>
  <sf-card title="非业务软件使用情况" class="non-office-apps">
    <el-row class="title" :gutter="20">
      <el-col :span="3">软件</el-col>
      <el-col :span="16">软件使用占比</el-col>
      <el-col :span="4" :offset="1">总时长</el-col>
    </el-row>
    <el-row
      v-for="item in list"
      :key="item.name"
      class="record"
      :gutter="20"
    >
      <el-col :span="3" class="name">
        {{item.name}}
      </el-col>
      <el-col :span="17" class="value">
        <div class="percentage-bar">
          <div class="filled" :style="{ width: `${item.usageRate * 100}%`}"></div>
          <span class="filled-label" :style="{ left: `${item.usageRate * 100}%`}">{{(item.usageRate * 100).toFixed(2)}}%</span>
        </div>
      </el-col>
      <el-col :span="4" class="hours">
        {{(item.totalHours || 0).toFixed(2)}}<span class="unit">小时</span>
      </el-col>
    </el-row>
  </sf-card>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    list: Array,
  },
}
</script>

<style lang="scss" scoped>
.non-office-apps {
  .title {
    @include text-regular($color-text-regular);
    > .el-col {
      text-align: center;
      &:first-child, &:last-child {
        text-align: right;
      }
    }
  }
  .record {
    padding-top: 50px;;
    @include title-secondary($color-text-primary);
    .name {
      @include text-primary($color-text-primary);
      text-align: right;
    }
    .value {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .percentage-bar {
        position: relative;
        width: 90%;
        height: 20px;
        overflow: hidden;
        .filled {
          height: 20px;
          background-color: #4DB7D9;
        }
        .filled-label {
          position: absolute;
          top: 0;
          line-height: 20px;
          padding-left: $padding;
        }
      }
    }
    .hours {
      @include flex-center();
      justify-content: flex-end;
    }
    .unit {
      margin-left: $padding-s;
      @include text-regular($color-text-regular);
    }
  }
}
</style>
<template>
  <sf-card
    title="过往3周软件类型占比"
  >
    <CategoryPercentage :dataDistribution="types"/>
    <RelatedAppTop5 :list="top5" />
  </sf-card>
</template>

<script>
import CategoryPercentage from './categoryPercentage';
import RelatedAppTop5 from './relatedAppTop5';
export default {
  props: {
    types: Array,
    top5: Array,
  },
  components: {
    RelatedAppTop5,
    CategoryPercentage,
  }
}
</script>

<style lang="scss" scoped>
.chart {
  height: 450px;
}
</style>
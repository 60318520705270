export default {
  white: '#fff',
  black: '#000',

  primary: '#3371FF',
  secondary: '#00CEBF',
  success: '#22CE88',
  warning: '#FAA111',
  danger: '#FF1333',

  blackGreen: '#16413E',
  deepGreen: '#26ACA4',
  deepRed: '#C23A34',
  grayBlue: '#529EE0',
  gray: '#333333',

  titlePrimary: '#2B2D42',
  titleSecondary: '#4C4D57',
  textPrimary: '#373E4E',
  textRegular: '#61666D',
  textSecondary: '#9499A3',
  textPlaceholder: '#A1A3A6',
  textDisabled: '#BBBBBB',

  background: '#F3F7FF',

  borderPrimary: '#B0B3B6',
  borderRegular: '#C4C8CC',
  borderSecondary: '#EFEFEF',

  softwareColors: ['#674890', '#4DB7D9', '#DCB722', '#0070D2', '#26ACA4', '#67A9E4', '#4295C9', '#EEF4FE', '#AFBCC8', '#F7A452', '#FFB03B', '#E16133', '#2DB77F', '#AFDCD9'],
}